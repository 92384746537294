import React, { useState } from 'react';
import { AppBar, Button, IconButton, makeStyles, Menu, MenuItem, Toolbar } from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  Menu as MenuIcon,
  Person as PersonIcon,
  Timer as TimerIcon,
} from '@material-ui/icons';
import { deleteAllCookies, DRAWER_WIDTH } from '../../utils/utils';
import { handleResponse, post } from '../../utils/network';
import config from '../../utils/config';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // necessary for content to be below app bar
  offset: theme.mixins.toolbar,
}));

const Header = ({ toggleIterations, toggleChronometers, iterationsOpen, chronometersOpen, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const paddingLeft = iterationsOpen ? DRAWER_WIDTH : 0;
  const paddingRight = chronometersOpen ? DRAWER_WIDTH : 0;
  const menuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    setAnchorEl(null);
    post('auth/logout').then(res => handleResponse(res, props)).then(() => {
      if(config.devEnvironment) deleteAllCookies();
      dispatch(logout());
      props.history.push('/login');
    });
  };

  const handleClickAgilefant = () => {
    setAnchorEl(null);
    window.open('https://af.dblandit.com', '_blank');
  };

  return (
    <React.Fragment>
      <AppBar
        style={{
          zIndex: 900,
          paddingLeft,
          paddingRight,
        }}
        position="fixed"
        elevation={1}
      >
        <Toolbar className={classes.toolbar}>
          {/* {iterationsOpen ? (
            <div />
          ) : (
            <IconButton onClick={toggleIterations} edge="start" color="inherit">
              <MenuIcon />
            </IconButton>
          )} */}
          <div>
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={menuOpen ? 'true' : undefined}
              onClick={handleClick}
              color="inherit"
              startIcon={<PersonIcon />}
              endIcon={<ArrowDropDownIcon />}
              style={{ marginRight: chronometersOpen ? 10 : 20 }}
            />
            <Menu
              open={menuOpen}
              onClose={handleClose}
              anchorEl={anchorEl}
              elevation={1}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={handleClickAgilefant}>Agilefant</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            {/* {!chronometersOpen && (
              <IconButton onClick={toggleChronometers} edge="end" color="inherit">
                <TimerIcon />
              </IconButton>
            )} */}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </React.Fragment>
  );
};

export default Header;

import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import history from './utils/history';
import LoginContainer from './pages/Login/LoginContainer';
import SSO from './pages/Sso';

const Routes = () => {
  return (
    <HashRouter history={history}>
      <Switch>
        <Route path="/login" component={LoginContainer} />
        <Route path="/home" component={Home} />
        <Route path="/sso" component={SSO} />
        <Route path="/404" component={() => <div>404</div>} />
        <Redirect from="/" to="/home" />
        <Redirect from="*" to="/404" />
      </Switch>
    </HashRouter>
  );
};
export default Routes;
